import adv from './adv'
import header from './header'
import footer from './footer'
import fixed from './fixed'
import banner from './banner'
import goodsgroup from './goodsgroup'
import seckill from './seckill'
import rubik from './rubik'
import title from './title'
import blank from './blank'
import brandhall from './brandhall'
import festival from './festival'

export default {
    header,
    adv,
    footer,
    fixed,
    banner,
    goodsgroup,
    seckill,
    rubik,
    title,
    blank,
    brandhall,
    festival
}
