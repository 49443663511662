







































import { Component, Prop, Vue } from 'vue-property-decorator'
import AttributeTabs from '@/components/decorate/attribute-tabs.vue'
import ColorSelect from '@/components/decorate/color-select.vue'
import MaterialSelect from '@/components/material-select/index.vue'
import AttributeItem from '@/components/decorate/attribute-item.vue'
@Component({
    components: {
        AttributeTabs,
        ColorSelect,
        MaterialSelect,
        AttributeItem
    }
})
export default class SearchAttribute extends Vue {
    @Prop({
        default: () => ({})
    })
    config!: any
    /** S data **/
    defaultConfig = {
        setTitle: true,
        setBg: true
    }
    /** E data **/
    /** S computed **/

    get pagesInfo() {
        const { pagesInfo } = this.$store.state.decorate
        return pagesInfo
    }

    set pagesInfo(val) {
        this.$store.commit('setPagesInfo', val)
    }

    get configs() {
        // console.log(this.config,Object.assign(this.config, this.defaultConfig))
        return Object.assign({}, this.defaultConfig, this.config)
    }

    /** E computed **/
    handleChange(val: string) {
        this.pagesInfo.common.title = val.trim()
    }
}
