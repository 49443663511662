
















































































import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class Widget extends Vue {
  /** S props **/

  @Prop() index!: number;
  @Prop({ default: () => ["hidden", "delete", "copy", "moveup", "movedown"] })
  operate!: string[];
  /** E props **/

  /** S computed **/
  get enabledBtn() {
    return (name: string) => this.operate.includes(name);
  }

  get isUp() {
    return (
      this.enabledBtn("moveup") &&
      (this.index === 0 ? false : true) &&
      !this.forbid(-1)
    );
  }

  get isDown() {
    return (
      this.enabledBtn("movedown") &&
      (this.index === this.pagesData.length - 1 ? false : true) &&
      !this.forbid(1)
    );
  }

  get selectIndex() {
    return this.$store.state.decorate.selectIndex;
  }

  set selectIndex(val) {
    this.$store.commit("setSelectIndex", val);
  }

  get pagesData() {
    return this.$store.state.decorate.pagesData;
  }

  set pagesData(val) {
    this.$store.commit("setPagesData", val);
  }

  get hidden() {
    const { pagesData, index } = this;
    return !pagesData[index].show;
  }
  /** E computed **/

  /** S methods **/
  forbid(index: number) {
    const widget = this.pagesData[this.selectIndex + index];
    return widget && widget.forbid;
  }
  /**
   * @description 设置选中索引
   */
  setSelect() {
    this.selectIndex = this.index;
  }

  /**
   * @description 隐藏组件
   */
  onHidden() {
    if (!this.enabledBtn("hidden")) {
      return;
    }
    const { pagesData, selectIndex } = this;
    pagesData[selectIndex].show = pagesData[selectIndex].show ? 0 : 1;
  }
  /**
   * @description 删除当前组件
   */
  onDelete() {
    if (!this.enabledBtn("delete")) {
      return;
    }
    this.pagesData.splice(this.index, 1);
    if (this.pagesData.length == this.selectIndex) {
      this.selectIndex--;
    }
  }

  /**
   * @description 复制组件
   */
  onCopy() {
    if (!this.enabledBtn("copy")) {
      return;
    }
    let { index } = this;
    let item = JSON.parse(JSON.stringify(this.pagesData[index]));
    this.pagesData.splice(index, 0, item);
  }
  /**
   * @description 移动组件
   */
  onMove(num: number) {
    let { index, isUp, isDown } = this;
    if ((num < 0 && !isUp) || (num > 0 && !isDown)) {
      return;
    }
    this.pagesData[index] = this.pagesData
      .splice(index + num, 1, this.pagesData[index])
      .pop();
    this.selectIndex = index + num;
  }

  /** E methods **/
}
