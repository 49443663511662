var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('widget-root',{attrs:{"styles":_vm.styles}},[_c('div',{staticClass:"seckill",style:({
            'background-color': _vm.styles.content_bg_color,
            'border-radius': ((_vm.styles.border_radius_top) + "px " + (_vm.styles.border_radius_top) + "px " + (_vm.styles.border_radius_bottom) + "px " + (_vm.styles.border_radius_bottom) + "px")
        })},[_c('div',{staticClass:"seckill-header flex",style:([_vm.headerStyle])},[_c('div',{staticClass:"flex-1 flex"},[(_vm.content.header_icon_image)?_c('img',{staticClass:"flex-none m-r-5",staticStyle:{"height":"22px"},attrs:{"src":_vm.$getImageUri(_vm.content.header_icon_image)}}):_vm._e(),_c('div',{staticClass:"line-1",style:({
                        'max-width': '200px',
                        color: _vm.styles.header_title_color,
                        'font-size': ((_vm.styles.header_title_size) + "px")
                    })},[_vm._v(" "+_vm._s(_vm.content.header_title)+" ")])]),(_vm.content.show_haeder_more)?_c('div',{staticClass:"more flex-none xs",style:({
                    color: _vm.styles.header_more_color
                })},[_vm._v(" "+_vm._s(_vm.content.header_more_text)+" "),_c('i',{staticClass:"el-icon-arrow-right"})]):_vm._e()]),_c('div',{staticClass:"seckill-goods",style:({ margin: ("-" + (_vm.styles.margin / 2) + "px"), padding: '10px' })},[_c('div',{staticClass:"goods-lists",class:{
                    lists: _vm.content.style == 1,
                    swiper: _vm.content.style == 2
                }},_vm._l((_vm.goods),function(item,index){return _c('div',{key:index,staticClass:"goods-wrap"},[_c('div',{staticClass:"goods-item",style:({
                            'background-color': _vm.styles.goods_bg_color,
                            margin: ((_vm.styles.margin / 2) + "px"),
                            'border-radius': ((_vm.styles.goods_border_radius) + "px")
                        })},[_c('div',{staticClass:"goods-image"},[_c('el-image',{attrs:{"src":item.image,"fit":"cover"}},[_c('img',{staticClass:"image-error",attrs:{"slot":"error","src":require("@/assets/images/goods_image.png"),"alt":""},slot:"error"})])],1),_c('div',{staticClass:"goods-info p-5"},[(_vm.content.show_title)?_c('div',{staticClass:"m-b-4 name",style:({
                                    color: _vm.styles.title_color
                                })},[_c('div',{class:_vm.content.style == 2 ? 'line-1' : 'line-2'},[_vm._v(" "+_vm._s(item.name || '这里是商品标题')+" ")])]):_vm._e(),(_vm.content.style == 1 && _vm.content.show_sell)?_c('div',{staticClass:"muted xs",style:({
                                    color: _vm.styles.sell_color
                                })},[_vm._v(" 已抢0件 ")]):_vm._e(),_c('div',{staticClass:"flex price-btn flex-wrap"},[_c('div',{staticClass:"flex-1 flex col-baseline"},[(_vm.content.show_price)?_c('div',{staticClass:"price weight-500 m-r-5 xl",style:({
                                            color: _vm.styles.price_color
                                        })},[_c('span',{staticClass:"xs"},[_vm._v("￥")]),_vm._v(_vm._s(parseFloat(item.min_activity_price) || '0')+" ")]):_vm._e(),(_vm.content.show_scribing_price)?_c('div',{staticClass:"muted line-through xs",style:({
                                            color: _vm.styles.scribing_price_color
                                        })},[_vm._v(" ￥"+_vm._s(parseFloat(item.min_price) || '0')+" ")]):_vm._e()]),(_vm.content.style == 1 && _vm.content.show_btn)?_c('div',{staticClass:"buy-btn xs",style:({
                                        'background-color': _vm.styles.btn_bg_color,
                                        color: _vm.styles.btn_color
                                    })},[_vm._v(" "+_vm._s(_vm.content.btn_text)+" ")]):_vm._e()])])])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }