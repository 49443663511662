























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import Detail from "./detail.vue";
@Component({
  components: {
    LsDialog,
    Detail,
  },
})
export default class Dialog extends Vue {
  @Prop({ default: () => ({}) }) value!: any[] | object;
  @Prop({ default: false }) disabled!: boolean;
  @Prop() type!: string;
  goods = [];
  handleConfirm() {
    this.$emit("input", this.goods);
  }
}
