

























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import AttributeTabs from '@/components/decorate/attribute-tabs.vue'
import ColorSelect from '@/components/decorate/color-select.vue'
import StyleChose from '@/components/decorate/style-chose.vue'
import Slider from '@/components/decorate/slider.vue'
import AttributeItem from '@/components/decorate/attribute-item.vue'
import LinkSelect from '../link-select/index.vue'
import MaterialSelect from '@/components/material-select/index.vue'
@Component({
    components: {
        AttributeTabs,
        ColorSelect,
        StyleChose,
        Slider,
        AttributeItem,
        LinkSelect,
        MaterialSelect,
        Draggable
    }
})
export default class Attribute extends Vue {
    /** S data **/
    styleData = [
        {
            name: '图片+文字',
            value: 1
        },
        {
            name: '图片',
            value: 2
        }
    ]
    /** E data **/

    /** S computed **/

    get content() {
        return this.$store.getters.content
    }

    set content(val) {
        let data = {
            key: 'content',
            value: val
        }
        this.$store.commit('setAttribute', data)
    }
    get styles() {
        return this.$store.getters.styles
    }

    /** E computed **/

    /** S methods **/
    onAdd(type: string) {
        const item = {
            icon: '',
            select_icon: '',
            name: '',
            type,
            link: {}
        }
        if (this.content.data.length > 5) {
            return this.$message.warning('最多五个导航')
        }
        this.content.data.push(item)
    }
    onDelete(index: number) {
        const { data } = this.content
        if (data.length <= 1) {
            return this.$message.warning('最少保留一个导航')
        }
        this.content.data.splice(index, 1)
    }
    /** E methods **/
}
