


















































































import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import LsPagination from "@/components/ls-pagination.vue";
import { RequestPaging, throttle } from "@/utils/util";
import { getMarketGoodsGoodsMarketGoodsLists } from "@/api/decorate";
@Component({
  components: {
    LsPagination,
  },
})
export default class Detail extends Vue {
  @Inject("visible") visible!: any;
  $refs!: { table: any };
  @Prop() value!: any;
  @Prop() goods!: any;
  @Prop({ default: "single" }) type!: "multiple" | "single";
  @Prop() limit!: number;
  @Prop({ default: false }) isSpec!: boolean;
  @Prop({ default: () => {} }) params!: Record<any, any>;
  // 是否展示虚拟商品
  @Prop({ default: false }) showVirtualGoods?: boolean;
  name = "";
  pager = new RequestPaging();
  selectedObj: any = {};

  @Watch("visible", { deep: true, immediate: true })
  visibleChange(val: any) {
    if (val.val) {
      this.getList();
    }
  }
  get selectData() {
    return this.value;
  }
  set selectData(val) {
    this.$emit("input", val);
  }

  get selectItem() {
    return (row: any) => {
      if (this.type == "single") {
        return this.selectData.id == row.id;
      }
      return this.selectData.some((item: any) => item.id == row.id);
    };
  }
  get selectAll() {
    const {
      pager: { lists },
    } = this;
    const ids: any[] = this.selectData.map((item: any) => item.id);
    if (!lists.length) {
      return false;
    }
    return lists.every((item) => ids.includes(item.id));
  }

  set selectAll(val) {
    const {
      pager: { lists },
    } = this;
    if (val) {
      for (let i = 0; i < lists.length; i++) {
        const item = lists[i];
        const ids: any[] = this.selectData.map((item: any) => item.id);
        if (!ids.includes(item.id)) {
          if (this.checkLength()) {
            return;
          }
          this.selectData.push(item);
        }
      }
    } else {
      lists.forEach((row) => {
        this.setSelectData(row);
      });
    }
  }
  getList(page?: number): void {
    // showVirtualGoods为true时，type=1，此时展示虚拟商品
    let type = undefined;
    if (this.showVirtualGoods) {
      type = 0;
    }

    page && (this.pager.page = page);
    this.pager
      .request({
        callback: getMarketGoodsGoodsMarketGoodsLists,
        params: {
          name: this.name,
          // is_spec: this.isSpec,
          // ...this.params,
          // type: type, // 为了显示出虚拟商品。
          // packge_id: this.$route.query.packge_id,
        },
      })
      .then((res: any) => {});
  }
  handleSelect($event: boolean, row: any) {
    if (this.type == "single") {
      if ($event) {
        this.selectData = row;
      } else {
        this.selectData = {};
      }
    } else if ($event) {
      if (this.checkLength()) {
        return;
      }
      this.selectData.push(row);
    } else {
      this.setSelectData(row);
    }
  }

  setSelectData(row: any) {
    const index = this.selectData.findIndex((item: any) => item.id == row.id);
    if (index != -1) {
      this.selectData.splice(index, 1);
    }
  }
  checkLength() {
    if (this.selectData.length >= this.limit) {
      this.$message({
        message: `选多选择${this.limit}件商品`,
        type: "warning",
      });
      return true;
    }
    return false;
  }
}
