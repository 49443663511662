



































import { Component, Prop, Vue } from 'vue-property-decorator'
import Indicator from '@/components/decorate/indicator.vue'
import WidgetRoot from '@/components/decorate/widget-root.vue'
import Goods from '../goodsgroup/contents.vue'
@Component({
    components: {
        Indicator,
        WidgetRoot,
        Goods
    }
})
export default class Contents extends Vue {
    @Prop() content!: any
    @Prop() styles!: any

    get itemStyle() {
        return (index: number) => {
            const {
                styles: { padding, color, active_color, active_bg_color },
                content: { active, has_active_bg }
            } = this
            const style: any = {
                padding: `0 ${padding}px`,
                color: color
            }
            if (active == index) {
                style['background-color'] = has_active_bg ? active_bg_color : ''
                ;(style.color = active_color), (style['font-weight'] = 500)
            }
            return style
        }
    }
}
