















import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class WidgetRoot extends Vue {
  /** S props **/

  @Prop() styles!: any;
  /** E props **/

  /** S computed **/

  /** E computed **/
}
