









































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import MouseCover from "@/components/decorate/mouse-cover.vue";
import Draggable from "vuedraggable";
import widgets from "./widgets";
import pcWidgets from "./pc-widgets";
@Component({
  components: {
    MouseCover,
    Draggable,
  },
})
export default class DecoratePhone extends Vue {
  @Prop({ default: () => [] }) component!: any[];
  @Prop({ default: false }) immersive!: boolean;
  @Prop({ default: false }) disabledDrag!: boolean;
  @Prop({ default: "mobile" }) client!: string;
  /** S data **/
  widgets = this.client == "mobile" ? widgets : pcWidgets;
  /** E data **/

  /** S computed **/
  get dragTarget() {
    return this.$store.state.decorate.dragTarget;
  }

  get pagesInfo() {
    return this.$store.state.decorate.pagesInfo;
  }

  get pagesData() {
    return this.$store.state.decorate.pagesData;
  }

  set pagesData(val: any[]) {
    this.$store.commit("setPagesData", val);
  }

  get dragIndex() {
    return this.$store.state.decorate.dragIndex;
  }

  set dragIndex(val) {
    this.$store.commit("setDragIndex", val);
  }

  get widgetData() {
    return this.$store.state.decorate.widgetData;
  }

  set widgetData(val) {
    this.$store.commit("setWidgetData", val);
  }
  get dragPosition() {
    return this.$store.state.decorate.dragPosition;
  }
  set dragPosition(val) {
    this.$store.commit("setDragPosition", val);
  }

  get selectIndex() {
    return this.$store.state.decorate.selectIndex;
  }

  set selectIndex(val) {
    this.$store.commit("setSelectIndex", val);
  }

  get showMouseCover() {
    return (index: number, position: string) => {
      if (this.forbid) {
        return false;
      }
      if (
        this.dragIndex === index &&
        this.widgetData &&
        position == this.dragPosition
      ) {
        return true;
      }
      return false;
    };
  }

  get bodyStyle() {
    const { background_color, background_image, background_type } =
      this.pagesInfo.common;
    switch (background_type) {
      case "0":
        return {};
      case "1":
        return { "background-color": background_color };
      case "2":
        return { "background-image": `url(${background_image})` };
    }
  }

  get forbid() {
    return (
      this.pagesData[this.dragIndex] && this.pagesData[this.dragIndex].forbid
    );
  }

  /** E computed **/

  /** S methods **/
  draggableEnd($event: any) {
    const { newIndex, oldIndex } = $event;
    this.dragIndex = -2;
    if (oldIndex == this.selectIndex) {
      this.selectIndex = newIndex;
      return;
    }
    if (oldIndex > this.selectIndex && this.selectIndex >= newIndex) {
      this.selectIndex++;
      return;
    }
    if (oldIndex < this.selectIndex && this.selectIndex <= newIndex) {
      this.selectIndex--;
    }
  }

  drop() {
    if (this.dragTarget !== "widget") {
      return;
    }
    // 禁止拖拽到含有forbid的组件
    if (this.forbid) {
      return;
    }
    this.$store.dispatch("addWidget");
  }
  dragover($event: any) {
    let index = parseInt($event.target.dataset.index);
    if (String(index) === "NaN") {
      return;
    }
    this.dragIndex = index;
    if ($event.target.dataset.type == "widget") {
      //获取Y轴移动值
      let y = parseFloat($event.offsetY);
      let h = parseFloat(String($event.target.offsetHeight / 2));
      if (y <= h) {
        this.dragPosition = "top";
      } else {
        this.dragPosition = "bottom";
      }
    }
    if (index == -1) {
      this.dragPosition = "bottom";
    }
  }

  onMove(e: any) {
    if (e.relatedContext.element.forbid) {
      return false;
    }
    return true;
  }
  /** E methods **/
}
