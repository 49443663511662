

























































import { Component, Prop, Vue } from 'vue-property-decorator'
import AttributeTabs from '@/components/decorate/attribute-tabs.vue'
import ColorSelect from '@/components/decorate/color-select.vue'
import StyleChose from '@/components/decorate/style-chose.vue'
import Slider from '@/components/decorate/slider.vue'
import AttributeItem from '@/components/decorate/attribute-item.vue'
import BannerList from '@/components/decorate/banner-list.vue'

@Component({
    components: {
        AttributeTabs,
        ColorSelect,
        StyleChose,
        Slider,
        AttributeItem,
        BannerList
    }
})
export default class Attribute extends Vue {
    /** S data **/

    indicatorData = [
        {
            name: '圆角',
            value: 1
        },
        {
            name: '圆形',
            value: 2
        },
        {
            name: '数字',
            value: 3
        }
    ]
    alignData = [
        {
            name: '居左',
            value: 'left'
        },
        {
            name: '居中',
            value: 'center'
        },
        {
            name: '居右',
            value: 'right'
        }
    ]
    /** E data **/

    /** S computed **/

    get content() {
        return this.$store.getters.content
    }

    set content(val) {
        let data = {
            key: 'content',
            value: val
        }
        this.$store.commit('setAttribute', data)
    }
    get styles() {
        return this.$store.getters.styles
    }

    /** E computed **/

    /** S methods **/

    /** E methods **/
}
