









































import { Component, Prop, Vue } from 'vue-property-decorator'
import AttributeTabs from '@/components/decorate/attribute-tabs.vue'
import ColorSelect from '@/components/decorate/color-select.vue'
import Slider from '@/components/decorate/slider.vue'
import AttributeItem from '@/components/decorate/attribute-item.vue'
@Component({
    components: {
        AttributeTabs,
        ColorSelect,
        Slider,
        AttributeItem
    }
})
export default class Attribute extends Vue {
    /** S computed **/

    get content() {
        return this.$store.getters.content
    }

    set content(val) {
        let data = {
            key: 'content',
            value: val
        }
        this.$store.commit('setAttribute', data)
    }
    get styles() {
        return this.$store.getters.styles
    }

    /** E computed **/
}
