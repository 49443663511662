








































import Swiper from "swiper";
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import WidgetRoot from "@/components/decorate/widget-root.vue";

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {
    WidgetRoot,
  },
})
export default class Contents extends Vue {
  @Prop() content!: object | any[];
  @Prop() styles!: object | any[];
  @Prop() index!: number;
  mySwiper: any = {};

  //
  customOption = {
    slidesPerView: 4,
    loop: true,
    observe: true,
    autoplay: true,
    // 如果需要前进后退按钮
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };
  @Watch("content.data", { deep: true, immediate: true })
  dataChange(val: any) {
    this.$nextTick(() => {
      this.mySwiper = null;
      this.initSwiper();
    });
  }
  /** S methods **/
  initSwiper() {
    let key: string = `.swiper-brand-${this.index}`;
    this.mySwiper = new Swiper(key, {
      ...this.customOption,
    });
  }
}
