














































































































import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class Goods extends Vue {
    @Prop() content!: any;
    @Prop() styles!: any;

    tagList: any[] = [
        {
            url: require("@/assets/images/xp.png"),
        },
        {
            url: require("@/assets/images/zk.png"),
        },
        {
            url: require("@/assets/images/rm.png"),
        },
    ];
    get goods() {
        const { data, goods_type, category } = this.content;
        if (goods_type == 2) {
            return [...Array(category.num).keys()].map(() => ({}));
        }
        return data.length ? data : [{}, {}, {}, {}];
    }
    get btnStyle() {
        const { btn_bg_color, btn_color, btn_border_radius, btn_border_color } =
            this.styles;
        const style = {
            "background-color": btn_bg_color,
            color: btn_color,
            "border-radius": `${btn_border_radius}px`,
            "border-color": btn_border_color,
        };
        return style;
    }
    goodsTag(type: any) {
        return this.tagList[type - 1].url;
    }
}
