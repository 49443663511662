





































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AttributeTabs from "@/components/decorate/attribute-tabs.vue";
import ColorSelect from "@/components/decorate/color-select.vue";
import StyleChose from "@/components/decorate/style-chose.vue";
import Slider from "@/components/decorate/slider.vue";
import AttributeItem from "@/components/decorate/attribute-item.vue";
import MaterialSelect from "@/components/material-select/index.vue";
import BannerList from '../banner-list.vue'

import Draggable from "vuedraggable";
@Component({
  components: {
    AttributeTabs,
    ColorSelect,
    StyleChose,
    Slider,
    AttributeItem,
    MaterialSelect,
    Draggable,
    BannerList
  },
})
export default class Attribute extends Vue {
  /** S data **/

  /** E data **/

  /** S computed **/

  get content() {
    return this.$store.getters.content;
  }

  set content(val) {
    let data = {
      key: "content",
      value: val,
    };
    this.$store.commit("setAttribute", data);
  }
  get styles() {
    return this.$store.getters.styles;
  }

  /** E computed **/

  /** S methods **/

  /** E methods **/
}
