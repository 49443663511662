



























































import { Component, Prop, Vue } from "vue-property-decorator";
import LinkSelect from "@/components/link-select/index.vue";
import MaterialSelect from "@/components/material-select/index.vue";
import Draggable from "vuedraggable";
@Component({
  components: {
    MaterialSelect,
    LinkSelect,
    Draggable,
  },
})
export default class StyleChose extends Vue {
  /** S props **/

  @Prop() value!: any[];

  @Prop({ default: 9999 }) limit!: number;

  @Prop() client!: string;
  @Prop({ default: false }) closeDelete!: boolean;

  /** E props **/

  /** S data **/
  $refs!: { materialSelect: any };
  index = -1;

  /** E data **/
  get banner() {
    return this.value;
  }

  set banner(val) {
    this.$emit("input", val);
  }

  /** S methods **/
  handleAdd() {
    if (this.banner.length < this.limit) {
      this.banner.push({
        url: "",
        link: {},
      });
    } else {
      this.$message.error(`最多添加${this.limit}张图片`);
    }
  }
  handleDelete(index: number) {
    if (this.banner.length <= 1) {
      return this.$message.error("最少保留一张图片");
    }
    this.banner.splice(index, 1);
  }

  /** E methods **/
}
