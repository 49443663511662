



















import { Component, Prop, Vue } from 'vue-property-decorator'
import WidgetRoot from '@/components/decorate/widget-root.vue'
@Component({
    components: {
        WidgetRoot
    }
})
export default class Contents extends Vue {
    @Prop() content!: any
    @Prop() styles!: any

    get videoStyle() {
        const { content } = this
        return content.poster
            ? {
                  'background-image': `url(${this.$getImageUri(content.poster)})`
              }
            : {}
    }
}
