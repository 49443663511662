





































// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Swiper from "swiper";
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import WidgetRoot from "@/components/decorate/widget-root.vue";

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {
    WidgetRoot,
  },
})
export default class Contents extends Vue {
  @Prop() content!: object | any[];
  @Prop() styles!: object | any[];
  @Prop() index!: number;
  mySwiper: any = {};
  //
  get text_arr() {
    let arr: any = [];
    let data: any = this.content;
    data.data.map((item: any) => {
      if (item.text != "") {
        arr.push(item.text);
      } else {
        arr.push(`  `);
      }
    });
    return arr;
  }
  @Watch("content.data", { deep: true, immediate: true })
  dataChange(val: any) {
    this.$nextTick(() => {
      this.initSwiper();
    });
  }
  @Watch("styles.position", { immediate: true })
  positionChange(val: any) {
    this.$nextTick(() => {
      this.initSwiper();
    });
  }
  /** S methods **/
  initSwiper() {
    this.mySwiper = null;
    let arr: any = this.text_arr;
    let key: string = `.swiper-festival-${this.index}`;
    this.mySwiper = new Swiper(key, {
      direction: "vertical", // 垂直切换选项
      loop: true,
      autoplay: true,
      // observer: true,
      pagination: {
        //分页容器的css选择器
        el: ".festival-pagination",
        // clickable: true,
        renderBullet(index: number, className: string) {
          let text = "";
          text = arr[index];
          return '<span class="' + className + '">' + text + "</span>";
        },
      },
    });
  }
  //
  // mounted() {
  //   this.$nextTick(() => {
  //     this.initSwiper();
  //   });
  // }
}
