





































































import { Component, Prop, Vue } from "vue-property-decorator";
import AttributeTabs from "@/components/decorate/attribute-tabs.vue";
import ColorSelect from "@/components/decorate/color-select.vue";
import StyleChose from "@/components/decorate/style-chose.vue";
import Slider from "@/components/decorate/slider.vue";
import AttributeItem from "@/components/decorate/attribute-item.vue";
import NavList from "@/components/decorate/nav-list.vue";
import LinkSelect from "../link-select/index.vue";
import MaterialSelect from "@/components/material-select/index.vue";
import GSelect from "../goods-select.vue";
@Component({
  components: {
    AttributeTabs,
    ColorSelect,
    StyleChose,
    Slider,
    AttributeItem,
    NavList,
    MaterialSelect,
    LinkSelect,
    GSelect,
  },
})
export default class Attribute extends Vue {
  /** S data **/

  /** E data **/

  /** S computed **/

  get content() {
    return this.$store.getters.content;
  }

  set content(val) {
    let data = {
      key: "content",
      value: val,
    };
    this.$store.commit("setAttribute", data);
  }
  get styles() {
    return this.$store.getters.styles;
  }

  /** E computed **/

  /** S methods **/

  /** E methods **/
}
