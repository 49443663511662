var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('widget-root',{attrs:{"styles":_vm.styles}},[_c('div',{staticClass:"title flex",style:({
            'justify-content': _vm.styles.align,
            'border-radius': ((_vm.styles.border_radius_top) + "px " + (_vm.styles.border_radius_top) + "px " + (_vm.styles.border_radius_bottom) + "px " + (_vm.styles.border_radius_bottom) + "px"),
        })},[(_vm.content.style == 1)?_c('div',{staticClass:"title-style",style:({
                'text-align':
                    _vm.styles.align == 'flex-start' ? 'left' : 'right',
            })},[_c('div',{staticClass:"title-content",style:({
                    color: _vm.styles.title_color,
                    'font-size': ((_vm.styles.title_font_size) + "px"),
                    'line-height': ((_vm.styles.title_line_height) + "px"),
                })},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]),(!_vm.content.hidden_subtitle)?_c('div',{staticClass:"title-subtitle",style:({
                    color: _vm.styles.subtitle_color,
                    'font-size': ((_vm.styles.subtitle_font_size) + "px"),
                    'line-height': ((_vm.styles.subtitle_line_height) + "px"),
                })},[_vm._v(" "+_vm._s(_vm.content.subtitle)+" ")]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }