
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CDialog from './dialog.vue'
@Component({
    components: {
        CDialog
    }
})
export default class GoodsSelect extends Vue {
    // API
    @Prop({ default: '1' }) API_TYPE!: '1'
    @Prop({ default: () => [] }) value!: any
    @Prop({ default: false }) disabled!: boolean
    @Prop() level!: number
    get selectData() {
        return this.value
    }
    set selectData(val) {
        this.$emit('input', val)
        this.$emit('change', val)
    }

    handleDelete() {
        this.selectData.name = ''
        this.selectData.id = ''
    }
}
