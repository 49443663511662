

















































import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import LsPagination from "@/components/ls-pagination.vue";
import { RequestPaging } from "@/utils/util";
import {
    apiCategoryCommonLists,
    apiCategoryGoodsCommonLists,
} from "@/api/goods";

@Component({
    components: {
        LsPagination,
    },
})
export default class Detail extends Vue {
    @Inject("visible") visible!: any;
    $refs!: { table: any };
    @Prop({ default: "1" }) API_TYPE!: "1";
    @Prop() value!: any;
    @Prop() level!: number;
    name = "";
    pager = new RequestPaging();
    get selectData() {
        return this.value;
    }
    set selectData(val) {
        this.$emit("input", val);
    }
    @Watch("visible", { deep: true, immediate: true })
    visibleChange(val: any) {
        if (val.val) {
            this.getList();
        }
    }
    getList(page?: number): void {
        page && (this.pager.page = page);
        this.pager
            .request({
                callback:
                    this.API_TYPE == '1'
                        ? apiCategoryCommonLists
                        : apiCategoryGoodsCommonLists,
                params: {
                    name: this.name,
                    level: this.level,
                    packge_id: this.$route.query.packge_id,
                },
            })
            .then((res: any) => {});
    }
    handleSelect($event: any, item: any) {
        if (!$event) {
            this.selectData = {};
            return;
        }
        this.selectData = {
            name: item.name,
            id: item.id,
        };
    }
}
