
































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AttributeTabs from "@/components/decorate/attribute-tabs.vue";
import ColorSelect from "@/components/decorate/color-select.vue";
import Slider from "@/components/decorate/slider.vue";
import AttributeItem from "@/components/decorate/attribute-item.vue";
import Draggable from "vuedraggable";
import LinkSelect from "../link-select/index.vue";
import MaterialSelect from "@/components/material-select/index.vue";
import StyleChose from "@/components/decorate/style-chose.vue";
import CubeLayout from "./cube-layout.vue";

@Component({
  components: {
    AttributeTabs,
    ColorSelect,
    Slider,
    Draggable,
    AttributeItem,
    MaterialSelect,
    LinkSelect,
    StyleChose,
    CubeLayout,
  },
})
export default class Attribute extends Vue {
  // S Data
  current = 0;
  styleData = [
    {
      name: "一行1个",
      value: 1,
      num: 1,
    },
    {
      name: "一行2个",
      value: 2,
      num: 2,
    },
    {
      name: "一行3个",
      value: 3,
      num: 3,
    },
    {
      name: "左1右2",
      value: 4,
      num: 3,
    },
    {
      name: "左2右2",
      value: 5,
      num: 4,
    },
    {
      name: "上1下2",
      value: 6,
      num: 3,
    },
  ];

  // E Data

  /** S computed **/

  get content() {
    return this.$store.getters.content;
  }

  set content(val) {
    let data = {
      key: "content",
      value: val,
    };
    this.$store.commit("setAttribute", data);
  }
  get styles() {
    return this.$store.getters.styles;
  }

  get currentData() {
    let index = this.current;
    if (this.content.data.length > 0) {
      return this.content.data[index];
    }
  }

  /** E computed **/
  @Watch("content.style", { immediate: true })
  styleChange(val: number) {
    this.current = 0;
    let num = this.styleData.find((item) => item.value == val)?.num || 1;
    this.content.data = this.handleArray(num);
  }
  /** S Methods **/
  currentChange(val: number) {
    this.current = val;
  }

  handleArray(num: number) {
    let data = JSON.parse(JSON.stringify(this.content.data));
    let array = [];
    for (let i = 0; i < num; i++) {
      if (data[i]) {
        array.push(data[i]);
      } else {
        array.push({
          url: "",
          link: {},
        });
      }
    }
    return array;
  }
  /** E Methods **/
}
