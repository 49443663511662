









































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import WidgetRoot from "@/components/decorate/widget-root.vue";
@Component({
  components: {
    WidgetRoot,
  },
})
export default class Contents extends Vue {
  @Prop() content!: any;
  @Prop() styles!: any;

  get list() {
    return this.content.data.length ? this.content.data : [{}, {}, {}];
  }
}
