











































import { Component, Prop, Vue } from "vue-property-decorator";
import WidgetRoot from "@/components/decorate/widget-root.vue";
@Component({
    components: {
        WidgetRoot,
    },
})
export default class Contents extends Vue {
    @Prop() content!: object | any[];
    @Prop() styles!: object | any[];
}
