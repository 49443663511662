












import { Component, Prop, Vue } from 'vue-property-decorator'
import WidgetRoot from '@/components/decorate/widget-root.vue'
import Goods from './goods.vue'
@Component({
    components: {
        WidgetRoot,
        Goods
    }
})
export default class Contents extends Vue {
    @Prop() content!: any
    @Prop() styles!: any
}
