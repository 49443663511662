import { render, staticRenderFns } from "./cube-layout.vue?vue&type=template&id=6f5fa005&scoped=true&"
import script from "./cube-layout.vue?vue&type=script&lang=ts&"
export * from "./cube-layout.vue?vue&type=script&lang=ts&"
import style0 from "./cube-layout.vue?vue&type=style&index=0&id=6f5fa005&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f5fa005",
  null
  
)

export default component.exports