

































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Goods from "../goods-select/detail.vue";
import Shop from "./shop.vue";
import Category from "./category.vue";
import Page from "./page.vue";
import Custom from "./custom.vue";
import Draw from "./draw.vue";

@Component({
  components: {
    Goods,
    Shop,
    Category,
    Page,
    Custom,
    Draw,
  },
})
export default class Detail extends Vue {
  @Prop() value!: any;
  @Prop({ default: "mobile" }) client!: string;
  activeName: any[] = [];
  linkType = "shop";
  lists = [
    {
      name: "商城页面",
      key: "shop",
      children: [
        {
          name: "基础页面",
          type: "shop",
          link: {
            path: "",
            name: "",
            params: {},
            type: "shop",
          },
        },
      ],
    },
    {
      name: "商品",
      key: "goods",
      children: [
        {
          name: "普通商品",
          type: "goods",
          link: {
            path: "/goods_details",
            name: "普通商品",
            params: {},
            type: "goods",
          },
        },
        // {
        //   name: "秒杀商品",
        //   type: "seckill",
        //   link: {
        //     path: "/goods_details_seckill",
        //     name: "秒杀商品",
        //     params: {},
        //     type: "seckill",
        //   },
        // },
        {
          name: "商品分类",
          type: "category",
          link: {
            path: "/category",
            name: "商品分类",
            params: {},
            type: "category",
          },
        },
      ],
    },
    // {
    //   name: "营销活动",
    //   key: "marking",
    //   children: [
    //     {
    //       name: "营销活动页面",
    //       type: "marking",
    //       link: {
    //         path: "",
    //         name: "",
    //         params: {},
    //         type: "marking",
    //       },
    //     },
    //   ],
    // },
  ];

  get link(): any {
    let itemLink: any = {};
    this.lists.forEach((item) => {
      const citem = item.children.find((citem) => citem.type == this.linkType);
      citem && (itemLink = citem);
    });
    return itemLink?.link;
  }
  set link(val) {
    this.lists.forEach((item) => {
      item.children.forEach((citem) => {
        if (citem.type == this.linkType) {
          val && (citem.link = val);
          if (!this.activeName.includes(item.key)) {
            this.activeName.push(item.key);
          }
        }
      });
    });
  }

  @Watch("value", { immediate: true })
  valueChange(val: any) {
    if (!val) {
      return;
    }
    this.linkType = val.type || "shop";
    this.link = val;
  }

  @Watch("link", { deep: true })
  linkChange(val: any) {
    this.$emit("input", val);
  }

  setLinkParams(item: any) {
    if (!item.id) {
      return (this.link.params = {});
    }
    this.link &&
      (this.link = {
        ...this.link,
        params: { id: item.id, name: item.name },
      });
  }
}
