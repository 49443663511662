


























































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import AttributeTabs from "@/components/decorate/attribute-tabs.vue";
import ColorSelect from "@/components/decorate/color-select.vue";
import StyleChose from "@/components/decorate/style-chose.vue";
import Slider from "@/components/decorate/slider.vue";
import AttributeItem from "@/components/decorate/attribute-item.vue";
import LinkSelect from "../link-select/index.vue";
import StyleSelect from "@/components/decorate/style-select.vue";
import MaterialSelect from "@/components/material-select/index.vue";

@Component({
  components: {
    AttributeTabs,
    ColorSelect,
    StyleChose,
    Slider,
    AttributeItem,
    LinkSelect,
    StyleSelect,
    MaterialSelect,
  },
})
export default class Attribute extends Vue {
  /** S data **/

  styleData = [
    {
      value: 1,
      img: require("@/assets/images/title_style1.png"),
      text: "风格1",
    },
    {
      value: 2,
      img: require("@/assets/images/title_style2.png"),
      text: "风格2",
    },
  ];
  alignData = [
    {
      name: "左对齐",
      value: "left",
    },
    {
      name: "居中",
      value: "center",
    },
  ];
  resetColor = "";
  /** E data **/

  /** S computed **/

  get content() {
    return this.$store.getters.content;
  }

  get styles() {
    return this.$store.getters.styles;
  }

  /** E computed **/
}
